import React, {useRef, useState} from 'react';
import baseStyles from '@components/PageLayouts/Header/header.module.scss';
import {HEADER_PORTAL_ID, HEADER_WRAPPER_ID, HEADER_ID} from '@constants/base';
import cn from 'classnames';

import styles from '@components/PageLayouts/Header/Standard/standardheader.module.scss';
import {TPageLayoutAttributesBase} from '@components/PageLayouts/pagelayouts.constants';
import {PartnerBaseAttributes} from '@integrations/partners/partners.types';

const StandardHeaderWrapper = ({
  children,
  pageConfig,
  partner,
}: {
  children: React.ReactElement<any, string>;
  pageConfig: TPageLayoutAttributesBase;
  partner?: PartnerBaseAttributes;
}) => {
  /* local state */
  const [addedClass, setAddedClass] = useState<string>('');
  const topNavHeaderContainer = useRef() as React.MutableRefObject<HTMLDivElement>;

  /* styles */
  const containerStyles = cn(styles.container, {
    [styles.navContainer]: true,
    [addedClass]: addedClass,
  });

  const appendHeaderStyles = (prependClass: string) => {
    setAddedClass(prependClass);
  };

  const ClonedElement = React.cloneElement(children, {appendHeaderStyles, partner});

  return (
    <>
      <div ref={topNavHeaderContainer} className={baseStyles.topNavWrapper} id={HEADER_WRAPPER_ID}>
        <header id={HEADER_ID}>
          <div className={containerStyles} data-testid="standard-header">
            {ClonedElement}
          </div>
        </header>
        <div id={HEADER_PORTAL_ID} />
      </div>
    </>
  );
};

export default StandardHeaderWrapper;

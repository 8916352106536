import React, {memo} from 'react';
import cn from 'classnames';
import {Tooltip} from 'ht-styleguide';
import dynamic from 'next/dynamic';

/* Components */
import CartPreviewIcon from '@components/PageLayouts/Header/Parts/CartPreviewIcon';
import PhoneIcon from '@components/PageLayouts/Header/Parts/PhoneIcon';
import ChatOrPhoneIcon from '@components/PageLayouts/Header/Parts/ChatOrPhoneIcon';
import Either from '@components/Either';
import LogosDisplay from '@components/PageLayouts/Header/Parts/LogosDisplay';

/* Queries & Integrations */
import usePartnerCache from '@integrations/partners/usePartnerQuery.cache';
import {PartnerBaseAttributes} from '@integrations/partners/partners.types';

/* Stores */
import {useResponsiveIsMobileOrTablet} from '@store/client/store.responsive';
import { useTrackingPhone } from '@store/client/store.tracking';

/* Hooks */
import useToggle from '@hooks/useToggle';

/* Utils / Constants */
import {canDerivePartnerLogo} from '@integrations/partners/partners.utils';
import {LAYOUT_PAGE_TYPES, TPageLayoutAttributesBase} from '@components/PageLayouts/pagelayouts.constants';

/* Styles */
import styles from '../partner.module.scss';

/* Dynamic Component */
const CobrandHeaderDynamicComponent = dynamic(() => import('@components/PageLayouts/Header/Partner/CobrandHeader'), {ssr: false});
const ChatPreviewIconDynamic = dynamic(() => import('@components/PageLayouts/Header/Parts/ChatPreviewIcon'), {ssr: false});

type HeaderPartnerDefaultProps = {
  pageConfig?: TPageLayoutAttributesBase;
  partner: PartnerBaseAttributes;
};

// To Do - format phone number?
const HeaderPartnerDefault = ({pageConfig, partner}: HeaderPartnerDefaultProps) => {
  /* Local Store */
  const {toggleState: openChatPhone, setBooleanToggle: setOpenChatPhone} = useToggle(false);

  /* Hooks */
  const cachedPartner = usePartnerCache();

  /* Store */
  const isMobileOrTablet = useResponsiveIsMobileOrTablet();

  /* Local Constants */
  const localPartner = (partner || cachedPartner) as PartnerBaseAttributes;
  const logos = canDerivePartnerLogo(localPartner);
  const clientStatePhone = useTrackingPhone();

  /**
   * We are offering two partners views.
   *   1. The "cobrand" view. Center images.
   *   2. The "default" view. Left aligned. With a phone number? & Cart
   */
  return (
    <Either condition={Boolean(logos && localPartner?.isCobranded && localPartner.page === LAYOUT_PAGE_TYPES.CART)}>
      {/* We are in cart flow, show the minimal header with cobrand logo */}
      <CobrandHeaderDynamicComponent partner={localPartner} />

      {/* We are not in cart flow */}
      <nav className={cn('htHeaderContainer', styles.container)}>
        <div id="left" className={styles.leftContainer}>
          <LogosDisplay partner={localPartner} classType="default" />
        </div>
        <div className={styles.rightContent}>
          {clientStatePhone && (
            <Either condition={!isMobileOrTablet}>
              <a href={clientStatePhone?.link} className={cn('p1 -no-decoration marginRight-small1', styles.phoneLink)}>
                <PhoneIcon />
                <Tooltip content="24/7 Customer Support">
                  <span className={styles.phoneNumber}>{clientStatePhone.title}</span>
                </Tooltip>
              </a>
              <ChatOrPhoneIcon onHandleChatPhoneClick={setOpenChatPhone} />
            </Either>
          )}
          {openChatPhone && <ChatPreviewIconDynamic toggleState={setOpenChatPhone} showDropdown={openChatPhone} />}
          <CartPreviewIcon />
        </div>
      </nav>
    </Either>
  );
};

export default memo(HeaderPartnerDefault);
